import './bootstrap';
import { createApp } from 'vue';
import Cart from './Components/Cart.vue';
import ProductGallery from './Components/ProductGallery.vue';
import DeliveryAddress from './Components/DeliveryAddress.vue';
import DeliveryAddressMobile from './Components/DeliveryAddressMobile.vue';
import ImageComponent from './Components/ImageComponent.vue';
import ImageComponentAdmin from './Components/ImageComponentAdmin.vue';
import ImageUpload from './Components/ImageUpload.vue';
import CartView from './Components/CartView.vue';
import CartResume from './Components/CartResume.vue';
import OrderView from './Components/OrderView.vue';
import IntegratorIcon from './Components/IntegratorIcon.vue';
import ProductSelector from './Components/ProductSelector.vue';
import PauseNewOrdersPopup from './Components/PauseNewOrdersPopup.vue';

//admin
import AdminStore from './Components/Admin/AdminStore.vue';
import AdminOrder from './Components/Admin/AdminOrder.vue';
import AdminCompany from './Components/Admin/AdminCompany.vue';
import AdminOrdersManager from './Components/Admin/OrdersManager.vue';
import AdminFinance from './Components/Admin/AdminFinance.vue';

//merchant
import MerchantDashboard from './Components/Merchant/MerchantDashboard.vue';
import MerchantStore from './Components/Merchant/MerchantStore.vue';
import MerchantOrdersManager from './Components/Merchant/MerchantOrdersManager.vue';
import MerchantNotification from './Components/Merchant/MerchantNotification.vue';

//fleet
import FleetOrdersManager from './Components/Fleet/OrdersManager.vue';

import Profile from './Components/Profile.vue';
import Vue3Lottie from 'vue3-lottie'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import print from 'vue3-print-nb'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import mitt from 'mitt';

const emitter = mitt();
const app = createApp({});

app.component("Cart", Cart);
app.component("ProductGallery", ProductGallery);
app.component("DeliveryAddress", DeliveryAddress);
app.component("DeliveryAddressMobile", DeliveryAddressMobile);

app.component("AdminStore", AdminStore);
app.component("AdminOrder", AdminOrder);
app.component("AdminCompany", AdminCompany);
app.component("AdminOrdersManager", AdminOrdersManager);
app.component("AdminFinance", AdminFinance);

app.component("FleetOrdersManager", FleetOrdersManager);



app.component("ImageComponent", ImageComponent);
app.component("ImageComponentAdmin", ImageComponentAdmin);
app.component("ImageUpload", ImageUpload);
app.component("CartView", CartView);
app.component("OrderView", OrderView);
app.component("CartResume", CartResume);
app.component("IntegratorIcon", IntegratorIcon);
app.component("ProductSelector", ProductSelector);
app.component("PauseNewOrdersPopup", PauseNewOrdersPopup);

//Merchant
app.component("MerchantDashboard", MerchantDashboard);
app.component("MerchantStore", MerchantStore);
app.component("MerchantOrdersManager", MerchantOrdersManager);
app.component("MerchantNotification", MerchantNotification);

app.component("Profile", Profile);
app.use(Vue3Lottie)
app.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCRsxESXWnCavSql_W5eCENf_gwtqV0yoA',
    },
  })
app.use(print)
app.component('EasyDataTable', Vue3EasyDataTable);

app.config.globalProperties.emitter = emitter;

app.mount("#app");
