<template>
</template>

<script setup>
import { initializeApp } from 'firebase/app'
import {getMessaging, getToken, onMessage } from 'firebase/messaging'
</script>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      config : {
        apiKey: "AIzaSyCT9f82ToyHoAII_n4z-nn0hK_MFRpwVHc",
        authDomain: "eatz--app.firebaseapp.com",
        projectId: "eatz--app",
        storageBucket: "eatz--app.appspot.com",
        messagingSenderId: "337302425238",
        appId: "1:337302425238:web:209ff62352fcdd716c3cb7",
        measurementId: "G-5NL0YVZPMS"
      },
    };
  },
  mounted() {  
    const firebase = initializeApp(this.config);
    
    console.log('firebase2','initializeApp');
    const messaging = getMessaging(firebase);

    getToken(messaging, {vapidKey: 'BIQjEOTpw-v5gEIsqnuTY1ACZNQvt57-1VvxXFjxdavexWV9TLJxpbWcwrKYYleXCQUEWLzIaWihY9otv9OkPgo'}).then((token) => {
      
      console.log('firebase','getToken', token);
      console.log('firebase','axios.post');              
      axios.post("/merchant/savePushToken",{
          _method:"POST",
          token
      }).then(({data})=>{
          console.log(data)
      }).catch(({response:{data}})=>{
          console.error(data)
      })

    }).catch(function (err) {
        console.log(`Token Error :: ${err}`);
    });


  onMessage(messaging, (payload) => {
    console.log('onMessaging', JSON.stringify(payload));
    new Notification(payload.notification.title, { body: payload.notification.body});
  });


  },
  methods: {
  },
};
</script>
