<template>    
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4 mb-4">

    <div class="gap-4">
      <div class="w-full px-8 py-4" v-if="order.type == 'delivery' && order.status != 'canceled' && order.status != 'ready'">
        <div class="relative flex items-center justify-between w-full mx-0">
          <div class="absolute left-0 top-2/4 h-0.5 w-[98%] -translate-y-2/4 bg-gray-300"></div>
          <div class="absolute left-0 top-2/4 h-0.5 w-[98%] -translate-y-2/4 bg-gray-900 transition-all duration-500">
          </div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center">1</div>

          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center"
          v-if="order.status == 'ON_DELIVERY' || order.status == 'DELIVERED'">2</div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center"
          v-else>2</div>

          <div class="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-900 rounded-full place-items-center"
          v-if="order.status == 'DELIVERED'">3</div>
          <div class="relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center"
          v-else>3</div>
        </div>
      </div>

      <div v-if="order.type == 'delivery'">
        <div class="">
          <div class="text-lg font-bold">
            Pedido #{{order.code}}
          </div>
          <div class="text-lg font-bold" v-if="order.status == 'NEW' && order.status == 'NEW' || order.status == 'SELECTED' || order.status == 'ON_PREPARING' || order.status == 'READY'">
            Estamos a preparar seu pedido...
          </div>
          <div class="text-lg font-bold" v-if="order.status == 'ON_DELIVERY'">
            A caminho da sua localização...
          </div>
          <div class="text-lg font-bold" v-if="order.status == 'CANCELED'">
            Seu pedido está cancelado
          </div>
        </div>
        <div class="" v-if="order.status != 'DELIVERED'">
          Chegada prevista: {{ format_date(order.estimated_delivery_at, 'HH:mm') }}
        </div>
        <div class="" v-else>
          Pedido entregue: {{ format_date(order.delivered_time, 'DD/MM/YYYY HH:mm') }}
        </div>
        <Vue3Lottie animationLink="/images/lottie/order_new.json" :height="200" :width="200" v-if="order.status == 'NEW' || order.status == 'SELECTED'"/>
        <Vue3Lottie animationLink="/images/lottie/order_onpreparing.json" :height="200" :width="200" v-if="order.status == 'ON_PREPARING' || order.status == 'READY'"/>
        <Vue3Lottie animationLink="/images/lottie/order_ondelivery.json" :height="200" :width="200" v-if="order.status == 'ON_DELIVERY'"/>
        <Vue3Lottie animationLink="/images/lottie/order_finished.json" :height="200" :width="200" v-if="order.status == 'DELIVERED'"/>

      </div>
      <div v-else class="mt-8">
          <div class="text-lg font-bold">
            Pedido #{{order.code}}
          </div>
        Estamos a preparar o seu pedido!

        <Vue3Lottie animationLink="/images/lottie/order_onpreparing.json" :height="200" :width="200"/>
      </div>
      
      <div class="" v-if="order.status != 'DELIVERED'">
        Se precisar de ajuda, carregue no botão abaixo para ligar para nós: {{ store.phone }}
      </div>
      <div class="flex flex-col items-center my-4" v-if="order.status != 'DELIVERED'">
        <button class="px-6 py-3 rounded-3xl bg-primary text-white transition-all hover:bg-primary" @click="callToStore">
          Ligar para o restaurante
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 md:mt-20 mt-0">
      <div class="" v-if="order.type == 'delivery'">
        <div class="text-lg font-bold">
          Detalhes da entrega
        </div>        
        
        <div class="">
          {{ order.eater_address}}, {{ order.eater_address_number}} {{ order.eater_appartment_number}} - {{ order.eater_zipcode}}
        </div>
        
        <GMapMap class="my-4 w-full h-60 relative"
          :center="findCenter([{ lat: format_float(order.eater_address_lat), lng: format_float(order.eater_address_lng) }, { lat: format_float(order.store.lat), lng: format_float(order.store.lng) }])"
          :zoom="13"
          map-type-id="terrain"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          }"
        >
          <GMapMarker :position="{ lat: format_float(order.store.lat), lng: format_float(order.store.lng) }"/>
          <GMapMarker :position="{ lat: format_float(order.eater_address_lat), lng: format_float(order.eater_address_lng) }"/>

          <GMapPolyline :path="mapPolyline" :editable="false" />
        </GMapMap>
      </div>
      <div class="" v-else>
        <div class="text-lg font-bold">
          Detalhes para levantamento
        </div>        
        
        <div class="">
          <b>{{ store.name}}</b>
        </div>  
        
        <div class="">
          {{ store.address}}, {{ store.address_number}} {{ store.appartment_number}} - {{ store.zipcode}}
        </div>
        
        <GMapMap class="my-4 w-full h-60 relative"
          :center="{ lat: format_float(store.lat), lng: format_float(store.lng) }"
          :zoom="15"
          map-type-id="terrain"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          }"
        >
          <GMapMarker :position="{ lat: format_float(store.lat), lng: format_float(store.lng) }"/>
        </GMapMap>
      </div>
      
      <div class="">
        <div class="text-lg font-bold">
          Resumo do pedido
        </div>
        <div class="">
          <div v-for="item in order.items" v-bind:key="item.id" class="grid grid-cols-2">
            <div class="text-left"><span v-if="item.name != 'Delivery' && item.name != 'Taxa de entrega' && item.name != 'Taxa de Serviço'">{{ item.quantity }} x </span>{{ item.name }}</div>
            <div class="w-full">{{ toCurrency(item.price * item.quantity) }}</div>
          </div>
          <br/>
          <div class="grid grid-cols-2">
            <div class="font-bold">Total</div>
            <div class="text-right">{{ toCurrency(order.price_with_discount) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Icon from "lucide-vue-next";
import moment from 'moment'
import Pusher from 'pusher-js'
</script>

<script>
Pusher.logToConsole = false

export default {
  props: {
    store: { type: Object },
    order: { type: Object },
  },
  data() {
    return {
      pusher: null,
      products: [],
      orderValues: {},
      itemsCount: 0,
      mapPolyline: [
        {lat: this.format_float(this.order.store.lat), lng: this.format_float(this.order.store.lng)},
        {lat: this.format_float(this.order.eater_address_lat), lng: this.format_float(this.order.eater_address_lng)},
      ],
    };
  },
  mounted() {
    this.pusher = new Pusher('5ffe2ab47cbc08c20ae9', { cluster: 'eu' })
    this.channelOrders = this.pusher.subscribe('orders')
    this.channelOrders.bind('orders', data => {
      if(data?.order_id == this.order.code) location.reload();
    })
  },
  created() {
  },
  methods: {
    onOrderUpdated(order) {
      this.order = order;
    },

    newOrderReceived(data) {
      console.log("newOrderReceived", data);
    },

    callToStore(){
      window.open='tel:'+this.store.phone;
    },

    format_date(value, format){
      if (value) {
        return moment(String(value)).format(format)
      }
    },

    format_float(value){
      return parseFloat(value);
    },

    toCurrency(value) {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-PT", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    },

    findCenter(markers) {
      const lats = markers.map(m => m.lat);
      const lngs = markers.map(m => m.lng);
      return {
        lat: (Math.min(...lats) + Math.max(...lats)) / 2,
        lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
      };
    }
  },
};
</script>
