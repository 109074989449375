<template>
  <div
    class="md:bg-cover bg-center bg-no-repeat bg-cover rounded-lg"
    v-bind:style="{'background-image': 'url('+this.backgroundimage+')'}"
  >
    &nbsp;
  </div>
</template>

  <script>
export default {
  props: {
    image_url: { type: String, default: null },
    store_id: { type: String, default: null },
    name: { type: String, default: "" },
    no_cache: { type: Boolean, default: false },
  },
  data() {
    return {
      backgroundimage: this.image_url != '' && this.image_url != null ? this.image_url : 'https://eatz-s3.s3.eu-north-1.amazonaws.com/images/noimage.png',
      cache_info: '',// '/?i='+Date.now(),
    };
  },
  mounted() {
  },
};
</script>
